<script>
import { required, minLength } from "vuelidate/lib/validators";
import validationMessages from "@/components/validations";
import Monitor from "@/services/Monitor";
import Swal from "sweetalert2";

export default {
  components: { validationMessages },

  data() {
    return {
      id: "",
      name: "",
      progress_goal: "",
      points_reward: "",
      season: "",
      description: "",
      showModal: false,
      submitted: false,
      tryingToSubmit: false,
      showLoader: false,
      csrf_token: localStorage.getItem("csrf_token"),
      error: null,
    };
  },

  validations: {
    id: {
      required,
    },
    name: {
      required,
      minLength: minLength(2),
    },
    // Add more validations here if needed
  },

  methods: {
    closeModal() {
      this.showModal = false;
      this.resetForm();
    },

    limitToSingleDigit(event) {
      if (event && event.target && event.target.value) {
        this.season = event.target.value.trim().slice(0, 1); // Take only the first character
      }
    },

    async addSocialLevel() {
      this.tryingToSubmit = true;
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.tryingToSubmit = false;
        return;
      }

      let formData = new FormData();
      formData.append("id", this.id);
      formData.append("name", this.name);
      formData.append("progress_goal", this.progress_goal);
      formData.append("points_reward", this.points_reward);
      formData.append("season", this.season);
      formData.append("description", this.description);
      formData.append("csrf_token", this.csrf_token);

      try {
        const response = await Monitor.addSocialMilestone(formData);
        if (response.error) {
          this.failedmsg("Failed to create Social Milestone");
        } else {
          this.successmsg("Social Milestone successfully created");
          this.closeModal();
          this.refreshData();
        }
      } catch (error) {
        this.error = error.response?.data?.error || "An error occurred";
        this.failedmsg(this.error);
      } finally {
        this.tryingToSubmit = false;
      }
    },

    successmsg(message) {
      Swal.fire("Success", message, "success");
    },

    failedmsg(message) {
      Swal.fire("Error", message, "error");
    },

    refreshData() {
      this.$emit("onRefresh");
    },

    resetForm() {
      this.id = "";
      this.name = "";
      this.progress_goal = "";
      this.points_reward = "";
      this.season = "";
      this.description = "";
      this.$v.$reset();
    },
  },
};
</script>

<template>
  <b-modal id="add_social_milestone" size="l" v-model="showModal" title="Social Milestone" title-class="font-18">
    <form @submit.prevent="addSocialLevel">
      <div class="row">
        <div v-if="submitted && $v.$invalid" class="text-danger">Complete all fields</div>
        <b-card-text>
          <div class="row">
            <div class="col-sm-12">
              <b-form-group label="Id" label-for="formrow-id-input" class="mb-3">
                <b-form-input v-model.trim="id" :class="{ 'is-invalid': submitted && $v.id.$error }"></b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Id'" :validationName="$v.id"></validationMessages>
              </b-form-group>

              <b-form-group label="Name" label-for="formrow-name-input" class="mb-3">
                <b-form-input v-model.trim="name" :class="{ 'is-invalid': submitted && $v.name.$error }"></b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Name'" :validationName="$v.name"></validationMessages>
              </b-form-group>

              <b-form-group label="Progress Goal" label-for="formrow-progress_goal-input" class="mb-3">
                <b-form-input v-model.trim="progress_goal"></b-form-input>
              </b-form-group>

              <b-form-group label="Points Reward" label-for="formrow-points_reward-input" class="mb-3">
                <b-form-input v-model.trim="points_reward"></b-form-input>
              </b-form-group>

              <b-form-group label="Season" label-for="formrow-season-input" class="mb-3">
                <b-form-input v-model.trim="season" @input="limitToSingleDigit" type="text" maxlength="1"></b-form-input>
              </b-form-group>

              <b-form-group label="Description" label-for="formrow-description-input" class="mb-3">
                <b-form-input v-model.trim="description"></b-form-input>
              </b-form-group>

              <input type="hidden" name="csrf_token" v-model="csrf_token">
            </div>
          </div>
        </b-card-text>
      </div>
    </form>

    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
      <b-button variant="primary" @click="addSocialLevel" :disabled="tryingToSubmit || showLoader">
        <b-spinner v-show="tryingToSubmit" small></b-spinner>
        Submit
      </b-button>
    </template>
  </b-modal>
</template>
